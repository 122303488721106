<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="New Message"
      sub-title=""
      @back="() => $router.go(-1)"
    >

      <!-- <template slot="extra">
      </template>

      <template slot="footer">
      </template> -->
      <div class="content">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">

          <a-form-item label="Sender ID type">
            <a-radio-group v-decorator="['sender_id_type', { rules: [{ required: true, message: 'Please select a sender id type' }] }]"
              button-style="solid"
            >
              <a-radio-button value="bulk" disabled>
                Bulk
              </a-radio-button>
              <a-radio-button value="alert">
                Alert
              </a-radio-button>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="Sender ID NT">
            <a-input
              v-decorator="['sender_id_nt', { rules: [{ required: true, message: 'Please enter a valid NT sender id' }] }]"
            />
          </a-form-item>
          <a-form-item label="Sender ID Ncell">
            <a-input
              v-decorator="['sender_id_ncell', { rules: [{ required: true, message: 'Please enter a valid Ncell sender id' }] }]"
            />
          </a-form-item>
          <a-form-item label="Sender ID Smart">
            <a-input
              v-decorator="['sender_id_smart', { rules: [{ required: true, message: 'Please enter a valid Smart sender id' }] }]"
            />
          </a-form-item>

          <a-form-item label="Upload Excel .xlsx file">
            <a-upload :multiple="false" :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload"
            v-decorator="['upload_file', { rules: [{ required: true, message: 'Please upload a valid Excel .xlsx file' }] }]"
            >
              <a-button> <a-icon type="upload" /> Select File </a-button>
            </a-upload>
            <a-button
              type="primary"
              :disabled="fileList.length === 0"
              :loading="uploading"
              style="margin-top: 16px"
              @click="handleUpload"
            >
              {{ uploading ? 'Uploading' : 'Start Upload' }}
            </a-button>
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">            
            <a-button type="primary" html-type="submit" :disabled="submitting_data">
              Send
            </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset" :disabled="submitting_data">
              Clear
            </a-button>
            <br/><br/>
            <a-alert v-if='error' :message='error_message' type="error" show-icon />
          </a-form-item>
        </a-form>
      </div>
    </a-page-header>
  </div>
</template>


<script>
// import moment from 'moment';
const Swal = require('sweetalert2');
const axios = require('axios');

// function isDoubleByte(str) {
//     for (var i = 0, n = str.length; i < n; i++) {   
//         if (str.charCodeAt( i ) > 255) { return true; }
//     }
//     return false;
// }

export default {
  data() {
    return {
      excelFileId: '',
      fileList: [],
      uploading: false,
      error: false,
      error_message: '',
      submitting_data: false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'new_message' }),
      status: '',
      // message: '',
      // messageValidateStatus: '',
      // messageHelp: '',
      // messageError: false,
      // contacts: {
      //     data: '',
      //     error: false,
      //     validateStatus: '',
      //     help: '',
      //     requiredAsterisk: true
      // }
    };
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed

  },
  methods: {
    handleSubmit (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        // if (this.message.trim()==='') {
        //     this.messageError=true
        //     this.messageValidateStatus='error'
        //     this.messageHelp='Please enter your sms message'
        // }
        // if (this.contacts.data.trim()==='') {
        //     this.contacts.error = true
        //     this.contacts.validateStatus='error'
        //     this.contacts.help='Please enter comma separated or multiline contacts'
        // }

        // // accept newlines, spaces and comma separation
        // //
        // // `9841999999,, 9841000000,
        // // 9841111111,
        // // 9841222222,

        // // 9841333333
        // // 9841444444
        // // 9841555555 9841666666,
        // // 9841777777`.replace(/(\r\n|\n|\r| )/gm,",").split(',').filter(item => item.length>0).join(',')
        // // "9841999999,9841000000,9841111111,9841222222,9841333333,9841444444,9841555555,9841666666,9841777777"
        // var contacts_comma_separated = this.contacts.data.replace(/(\r\n|\n|\r| )/gm,",").split(',').filter(item => item.length>0).join(',')

        if (!err && this.excelFileId!='') {
          values.excelFileId = this.excelFileId
        //   values.message = this.message
        //   values.contacts = contacts_comma_separated
        // //   console.log('Received values of form: ', values)
        //   // { sender_id_type: "alert", sender_id_nt: "a", sender_id_ncell: "b", sender_id_smart: "c", contacts: "d0,d1", message: "e" }

          this.submitting_data = true
          this.error = false
          this.error_message = ''

          var config = {
            method: 'post',
            url: process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/excel/send/',
            headers: { 
              'Authorization': 'JWT '+localStorage.jwt,
              'Content-Type': 'application/json'
            },
            data : values
          };

          axios(config)
          .then((response) => {
              // console.log(response.data)
              if (response.data.detail) {
                console.log('error!')
                this.handleError(response.data.detail)
              } else {
                Swal.fire({
                  position: 'bottom',
                  toast: true,
                  icon: 'success',
                  iconColor: '#fff',
                  title: '<span style="color:#fff">Your message has been queued</span>',
                  // title: 'Your message has been sent',
                  showConfirmButton: false,
                  timer: 10000,
                  // timerProgressBar: true,
                  // backdrop: false
                  background: '#0c9463' 
                })
                this.handleReset()
              }

              this.submitting_data = false
          })
          .catch((error) => {
              console.log('error!')
              console.log(error.response.data)

              this.handleError('unknown error!')

              this.submitting_data = false
          });    

        }
      });

      
    },
    handleReset () {
      this.error = false
      this.error_message = ''
      this.excelFileId = ''
      this.fileList = []
      this.form.resetFields()

      // this.messageValidateStatus = ''
      // this.messageHelp = ''
      // this.messageError=false
      // this.message = ''

      // this.contacts.data = ''
      // this.contacts.error = false
      // this.contacts.validateStatus=''
      // this.contacts.help=''
    },
    handleError (error_msg) {
      // console.log('handling error...')
      this.error_message = error_msg
      this.error = true
    },

    // fetchData () {
    //   this.reloading_data = true
    // }

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleUpload() {
      const { fileList } = this;
      const formData = new FormData();
      // fileList.forEach(file => {
      //   formData.append('files[]', file);
      // });
      formData.append('file', fileList[0]);
      this.uploading = true;

      // // You can use any AJAX library you like
      var config = {
            method: 'post',
            url: process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/excel/upload/',
            headers: { 
              'Authorization': 'JWT '+localStorage.jwt,
              'Content-Type': 'multipart/form-data'
            },
            data : formData
          };

      axios(config)
      .then((response) => {
        if (response.data.detail) {
          // console.log('error!')
          this.handleError(response.data.detail)
          this.uploading = false
        } else {
          // console.log(response.data.upload_id)
          this.error_message = ''
          this.error = false

          // this.fileList = [];
          this.excelFileId = response.data.upload_id
          this.uploading = false
          this.$message.success('Upload successful.')
        }
      })
      .catch((error) => {
        console.log(error.response.data)
        
        this.uploading = false;
        this.$message.error('Upload failed.');
      });  
    },
  
  },
  watch: {
      // message () {
      //     //   console.log(this.message)

      //     if (this.message.length>0) {
      //       this.messageValidateStatus = ''
      //       this.messageHelp = ''
      //       this.messageError=false
      //     }
      // },
      // 'contacts.data' () {

      //     if (this.contacts.data.length>0) {
      //       this.contacts.error = false
      //       this.contacts.validateStatus=''
      //       this.contacts.help=''
      //     }
      // }
  },
  computed: {
      // messageIsUnicode: function () {
      //     return isDoubleByte(this.message)
      // },
      // messageLength: function () {
      //     return this.message.length
      // },
      // pages: function () {
      //       if (this.messageLength===0) {
      //           return 1
      //       }
            
      //       if (this.messageIsUnicode) {
      //           if (this.messageLength>70) {
      //               return Math.ceil(this.message.length/67)
      //           } else {
      //               return 1
      //           }
      //       } else {
      //           if (this.messageLength>160) {
      //               return Math.ceil(this.message.length/153)
      //           } else {
      //               return 1
      //           }
      //       }
      // },
      // charactersRemaining: function () {
      //     if (this.messageIsUnicode) {
      //         if (this.messageLength>70) {
      //             return 67-(this.message.length%67)
      //         } else {
      //             return 70-this.message.length
      //         }
      //     } else {
      //         if (this.messageLength>160) {
      //             return 153-(this.message.length%153)
      //         } else {
      //             return 160-this.message.length
      //         }
      //     }
      // },
      // charactersAvailable: function () {
      //     if (this.messageIsUnicode) {
      //         if (this.messageLength>70) {
      //             return Math.ceil(this.message.length/67)*67
      //         } else {
      //             return 70
      //         }
      //     } else {
      //         if (this.messageLength>160) {
      //             return Math.ceil(this.message.length/153)*153
      //         } else {
      //             return 160
      //         }
      //     }
      // }
  }
}
</script>

<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="New Message"
      sub-title=""
      @back="() => $router.go(-1)"
    >

      <!-- <template slot="extra">
      </template>

      <template slot="footer">
      </template> -->
      <div class="content">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">

          <a-form-item label="Sender ID type">
            <a-radio-group v-decorator="['sender_id_type', { rules: [{ required: true, message: 'Please select a sender id type' }] }]"
              button-style="solid"
            >
              <a-radio-button value="bulk">
                Bulk
              </a-radio-button>
              <a-radio-button value="alert">
                Alert
              </a-radio-button>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="Sender ID NT">
            <a-input
              v-decorator="['sender_id_nt', { rules: [{ required: true, message: 'Please enter a valid NT sender id' }] }]"
            />
          </a-form-item>
          <a-form-item label="Sender ID Ncell">
            <a-input
              v-decorator="['sender_id_ncell', { rules: [{ required: true, message: 'Please enter a valid Ncell sender id' }] }]"
            />
          </a-form-item>
          <a-form-item label="Sender ID Smart">
            <a-input
              v-decorator="['sender_id_smart', { rules: [{ required: true, message: 'Please enter a valid Smart sender id' }] }]"
            />
          </a-form-item>

          <a-form-item label="Contacts" :validate-status="contacts.validateStatus" :help="contacts.help" :required="contacts.requiredAsterisk">
            <a-textarea v-model="contacts.data"
              placeholder="comma separated or multiline mobile numbers" :rows="5" 
            />
          </a-form-item>

          <a-form-item label="Message" :validate-status="messageValidateStatus" :help="messageHelp" :required="true"> 
            <a-textarea 
            placeholder="" :rows="4"
            v-model="message"
            />
            <div>
              <span style="float:left">
                  <span v-if="messageIsUnicode === true">Unicode</span>
                    <span v-else>Plaintext</span>
                    <span> / {{ pages }} pages</span>
              </span>

              <span style="float:right">
                <span>{{ messageLength }}/{{ charactersAvailable }}</span>
                
              </span>
            </div>
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">            
            <a-button type="primary" html-type="submit" :disabled="submitting_data">
              Send
            </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset" :disabled="submitting_data">
              Clear
            </a-button>
            <br/><br/>
            <a-alert v-if='error' :message='error_message' type="error" show-icon />
          </a-form-item>
        </a-form>
      </div>
    </a-page-header>
  </div>
</template>


<script>
// import moment from 'moment';
const Swal = require('sweetalert2');
const axios = require('axios');

function isDoubleByte(str) {
    for (var i = 0, n = str.length; i < n; i++) {   
        if (str.charCodeAt( i ) > 255) { return true; }
    }
    return false;
}

export default {
  data() {
    return {
      error: false,
      error_message: '',
      submitting_data: false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'new_message' }),
      status: '',
      message: '',
      messageValidateStatus: '',
      messageHelp: '',
      messageError: false,
      contacts: {
          data: '',
          error: false,
          validateStatus: '',
          help: '',
          requiredAsterisk: true
      }
    };
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed

  },
  methods: {
    handleSubmit (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (this.message.trim()==='') {
            this.messageError=true
            this.messageValidateStatus='error'
            this.messageHelp='Please enter your sms message'
        }
        if (this.contacts.data.trim()==='') {
            this.contacts.error = true
            this.contacts.validateStatus='error'
            this.contacts.help='Please enter comma separated or multiline contacts'
        }

        // accept newlines, spaces and comma separation
        //
        // `9841999999,, 9841000000,
        // 9841111111,
        // 9841222222,

        // 9841333333
        // 9841444444
        // 9841555555 9841666666,
        // 9841777777`.replace(/(\r\n|\n|\r| )/gm,",").split(',').filter(item => item.length>0).join(',')
        // "9841999999,9841000000,9841111111,9841222222,9841333333,9841444444,9841555555,9841666666,9841777777"
        var contacts_comma_separated = this.contacts.data.replace(/(\r\n|\n|\r| )/gm,",").split(',').filter(item => item.length>0).join(',')

        if (!err && !this.messageError && !this.contacts.error) {
          values.message = this.message
          values.contacts = contacts_comma_separated
        //   console.log('Received values of form: ', values)
          // { sender_id_type: "alert", sender_id_nt: "a", sender_id_ncell: "b", sender_id_smart: "c", contacts: "d0,d1", message: "e" }

          this.submitting_data = true
          this.error = false
          this.error_message = ''

          var config = {
            method: 'post',
            url: process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/send/',
            headers: { 
              'Authorization': 'JWT '+localStorage.jwt,
              'Content-Type': 'application/json'
            },
            data : values
          };

          axios(config)
          .then((response) => {
              // console.log(response.data)
              if (response.data.detail) {
                console.log('error!')
                this.handleError(response.data.detail)
              } else {
                Swal.fire({
                  position: 'bottom',
                  toast: true,
                  icon: 'success',
                  iconColor: '#fff',
                  title: '<span style="color:#fff">Your message has been sent</span>',
                  // title: 'Your message has been sent',
                  showConfirmButton: false,
                  timer: 10000,
                  // timerProgressBar: true,
                  // backdrop: false
                  background: '#0c9463' 
                })
                this.handleReset()
              }

              this.submitting_data = false
          })
          .catch((error) => {
              console.log('error!')
              console.log(error.response.data)

              this.handleError('unknown error!')

              this.submitting_data = false
          });    

        }
      });

      
    },
    handleReset () {
      this.error = false
      this.error_message = ''
      this.form.resetFields()

      this.messageValidateStatus = ''
      this.messageHelp = ''
      this.messageError=false
      this.message = ''

      this.contacts.data = ''
      this.contacts.error = false
      this.contacts.validateStatus=''
      this.contacts.help=''
    },
    handleError (error_msg) {
      // console.log('handling error...')
      this.error_message = error_msg
      this.error = true
    },

    // fetchData () {
    //   this.reloading_data = true
    // }
  
  },
  watch: {
      message () {
          //   console.log(this.message)

          if (this.message.length>0) {
            this.messageValidateStatus = ''
            this.messageHelp = ''
            this.messageError=false
          }
      },
      'contacts.data' () {

          if (this.contacts.data.length>0) {
            this.contacts.error = false
            this.contacts.validateStatus=''
            this.contacts.help=''
          }
      }
  },
  computed: {
      messageIsUnicode: function () {
          return isDoubleByte(this.message)
      },
      messageLength: function () {
          return this.message.length
      },
      pages: function () {
            if (this.messageLength===0) {
                return 1
            }
            
            if (this.messageIsUnicode) {
                if (this.messageLength>70) {
                    return Math.ceil(this.message.length/67)
                } else {
                    return 1
                }
            } else {
                if (this.messageLength>160) {
                    return Math.ceil(this.message.length/153)
                } else {
                    return 1
                }
            }
      },
      charactersRemaining: function () {
          if (this.messageIsUnicode) {
              if (this.messageLength>70) {
                  return 67-(this.message.length%67)
              } else {
                  return 70-this.message.length
              }
          } else {
              if (this.messageLength>160) {
                  return 153-(this.message.length%153)
              } else {
                  return 160-this.message.length
              }
          }
      },
      charactersAvailable: function () {
          if (this.messageIsUnicode) {
              if (this.messageLength>70) {
                  return Math.ceil(this.message.length/67)*67
              } else {
                  return 70
              }
          } else {
              if (this.messageLength>160) {
                  return Math.ceil(this.message.length/153)*153
              } else {
                  return 160
              }
          }
      }
  }
}
</script>

<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Settings"
      sub-title=""
      @back="() => $router.go(-1)"
    >

      <template slot="extra">
        <a-button-group size="default">
          <a-button key="1" type="primary" icon="reload" @click="refresh()">Refresh</a-button>
        </a-button-group>
      </template>

      <template slot="footer">
      </template>

      <div class="content">
            <a-tabs default-active-key="2" :animated="false">
                <!-- <a-tab-pane key="1" tab="Profile">

                </a-tab-pane> -->

                <a-tab-pane key="2" tab="Change Password">
                    <div>

                        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">

                            <a-form-item label="Old Password">
                                <a-input-password
                                v-decorator="['old_password', { rules: [{ required: true, message: 'Please enter your old password' }] }]"
                                />
                            </a-form-item>
                            <a-form-item label="New Password">
                                <a-input-password
                                v-decorator="['new_password', { rules: [{ required: true, message: 'Please enter your new password' }] }]"
                                />
                            </a-form-item>
                            <a-form-item label="Confirm New Password">
                                <a-input-password
                                v-decorator="['new_password_confirm', { rules: [{ required: true, message: 'Please enter your new password again' }] }]"
                                />
                            </a-form-item>

                            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">            
                                <a-button type="primary" html-type="submit" :disabled="submitting_data">
                                Update
                                </a-button>
                                <a-button :style="{ marginLeft: '8px' }" @click="handleReset" :disabled="submitting_data">
                                Cancel
                                </a-button>
                                <br /><br />
                                <a-alert v-if='error' :message='error_message' type="error" show-icon />
                            </a-form-item>
                        </a-form>

                    </div>
                </a-tab-pane>

                <a-tab-pane key="3" tab="Manage Users">
                    <div v-if="!add_edit_mode">
                        <a-space>
                            <a-button type="primary" @click="createUser">Create User</a-button>
                        </a-space>
                        <br /><br />
                        
                            <a-table 
                            :columns="columns"
                            :data-source="data"
                            :scroll="{ x:true }"
                            rowKey="id"
                            size="small"
                            :loading="reloading_data"
                            :pagination="false"
                            >
                                <span slot="id" slot-scope="id,data">
                                    <a-button key="{{id}}"  @click="handleEditUser(id,data)" type="default" icon="edit" size="small" />

                                    <!-- <a-popconfirm
                                        :disabled="data.retried==true"
                                        title="Permanently delete this user?"
                                        @confirm="() => handleDeleteUser(id, data)"
                                    > -->
                                    <a-popconfirm
                                        :disabled="data.retried==true"
                                        title="Permanently delete this user?"
                                        @confirm="() => handleDeleteUser(id)"
                                    >
                                        <a-button key="{{id}}"  type="danger" icon="delete" size="small" />
                                    </a-popconfirm>

                                </span>

                            </a-table>
                    </div>
                    <div v-if="add_edit_mode">

                        

                        <a-form :form="form_2" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="user_handleSubmit">
                            <h2>{{ this.add_or_edit }} User</h2>

                            <a-form-item label="Fullname">
                                <a-input
                                v-decorator="['fullname', { rules: [{ required: true, message: 'Please enter your full name' }] }]"
                                />
                            </a-form-item>
                            <a-form-item label="Email">
                                <a-input
                                v-decorator="['email', { rules: [{ required: true, message: 'Please enter your email' }] }]"
                                />
                            </a-form-item>
                            <a-form-item label="Username">
                                <a-input
                                v-decorator="['username', { rules: [{ required: true, message: 'Please enter your username' }] }]"
                                />
                            </a-form-item>
                            <!-- <a-form-item label="Password">
                                <a-input-password
                                v-decorator="['password', { rules: [{ required: true, message: 'Please enter your passsword' }] }]"
                                />
                            </a-form-item> -->

                            <a-form-item label="Password" :validate-status="user_password.validateStatus" :help="user_password.help" :required="user_password.requiredAsterisk">
                            <!-- <a-form-item label="Message" :required="false"> -->
                                <a-input-password v-model="user_password.data" />
                            </a-form-item>

                            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">            
                                <a-button type="primary" html-type="submit" :disabled="submitting_data_2">
                                Update
                                </a-button>
                                <a-button :style="{ marginLeft: '8px' }" @click="handleCancel_2" :disabled="submitting_data_2">
                                Cancel
                                </a-button>
                                <br /><br />
                                <a-alert v-if='error_2' :message='error_message_2' type="error" show-icon />
                            </a-form-item>
                        </a-form>

                    </div>

                </a-tab-pane>
            </a-tabs>

      </div>
    </a-page-header>
  </div>
</template>


<script>
// import moment from 'moment';
const Swal = require('sweetalert2');
const axios = require('axios');


const columns = [
  {
    title: 'Fullname',
    key: 'fullname',
    dataIndex: 'fullname',
    // fixed: 'left',
    // width: '100px',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    // fixed: 'left',
    // width: '150px',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    // fixed: 'left',
    // width: '120px',
  },
  {
    title: 'Actions',
    key: 'id',
    dataIndex: 'id',
    fixed: 'right',
    // width: '100px',
    scopedSlots: { customRender: 'id' }
  },
];

export default {
  data() {
    return {
        error: false,
        error_2: false,
        submitting_data: false,
        submitting_data_2: false,
        reloading_data: false,
        error_message: '',
        error_message_2: '',
        formLayout: 'horizontal',
        form: this.$form.createForm(this, { name: 'change_password' }),     
        form_2: this.$form.createForm(this, { name: 'add_edit_user' }),
        user_password: {
            data: '',
            error: false,
            validateStatus: '',
            help: '',
            requiredAsterisk: true,
        },
        add_edit_mode: false,
        add_or_edit: 'Add',
        selected_id: 0,
        columns,
        data: [],
    };
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  mounted () {
    // fetch the data when the view is created and the data is
    // already being observed

    // this.$nextTick(function () {
    //     this.form_2.getFieldDecorator('fullname');
    //     this.form_2.getFieldDecorator('email');
    //     this.form_2.getFieldDecorator('username');
    //     this.form_2.getFieldDecorator('password');
        
    //     this.form_2.setFieldsValue({
    //         'fullname': 'Bigyan Adhikary',
    //         'email': 'bigyan.adhikary@gmail.com'
    //         });
    //     // console.log('>>>>', this.form_2);
    // });
  },
  watch: {
      'user_password.data' () {

          if (this.user_password.data.length>0) {
            this.user_password.error=false
            this.user_password.validateStatus=''
            this.user_password.help=''
          }
      }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        //   console.log(err, values)

          if (!err) {
                this.submitting_data = true
                this.error = false
                this.error_message = ''

                var config = {
                    method: 'put',
                    url: process.env.VUE_APP_BACKEND_API_BASE_URL + '/account/changepassword/',
                    headers: { 
                    'Authorization': 'JWT '+localStorage.jwt,
                    'Content-Type': 'application/json'
                    },
                    data : values
                };

                axios(config)
                .then((response) => {
                    // console.log(response.data)
                    if (response.data.detail) {
                        console.log('error!')
                        this.handleError(response.data.detail)
                    } else {
                        Swal.fire({
                        position: 'bottom',
                        toast: true,
                        icon: 'success',
                        iconColor: '#fff',
                        title: '<span style="color:#fff">Your password has been changed</span>',
                        // title: 'Your message has been sent',
                        showConfirmButton: false,
                        timer: 10000,
                        // timerProgressBar: true,
                        // backdrop: false
                        background: '#0c9463' 
                        })
                        this.handleReset()
                    }

                    this.submitting_data = false
                })
                .catch((error) => {
                    console.log('error!')
                    console.log(error.response.data)

                    this.handleError('unknown error!')

                    this.submitting_data = false
                });
          }




      });

      this.submitting_data = false
    },
    
    handleReset () {
      this.submitting_data = false
      this.error = false
      this.error_message = ''
      this.form.resetFields()
    },
    handleError (error_msg) {
      // console.log('handling error...')
      this.error_message = error_msg
      this.error = true
    },
    handleCancel_2 () {
        this.handleReset_2()
        this.add_edit_mode = false
    },
    handleReset_2 () {
      this.submitting_data_2 = false
      this.error_2 = false
      this.error_message_2 = ''

      this.user_password.error=false
      this.user_password.validateStatus=''
      this.user_password.help=''
      this.user_password.data=''
      this.user_password.requiredAsterisk = true

      this.form_2.resetFields()
    },
    handleError_2 (error_msg) {
      // console.log('handling error...')
      this.error_message_2 = error_msg
      this.error_2 = true
    },
    fetchData () {
      this.reloading_data = true

      axios.get(process.env.VUE_APP_BACKEND_API_BASE_URL + '/account/users/',
              {
                headers: {
                    Authorization: "JWT " + localStorage.jwt
                }
              })
              .then((response) => {
                  this.data = response.data
                  
                  this.reloading_data = false
              })
              .catch((error) => {
                  console.log(error.response.data)
                  this.reloading_data = false
              });      
    },
    handleEditUser(id, data) {
        this.selected_id = id
        this.add_or_edit = 'Edit'
        this.add_edit_mode = true

        this.handleReset_2()

        this.user_password.requiredAsterisk = false

        // console.log(id, data)

        // var u = data.filter(function(x) { return x.id==id})[0]
        // console.log(data.fullname, data.email, data.username)

        this.form_2.getFieldDecorator('fullname');
        this.form_2.getFieldDecorator('email');
        this.form_2.getFieldDecorator('username');
        // this.form_2.getFieldDecorator('password');
        this.form_2.setFieldsValue({
            'fullname': data.fullname,
            'email': data.email,
            'username': data.username,
        });
    },
    createUser() {
        this.selected_id = 0
        this.add_or_edit = 'Create'
        this.add_edit_mode = true

        this.handleReset_2()

        this.user_password.requiredAsterisk = true
        
    },
    handleDeleteUser(id) {
            this.selected_id = id
            console.log(id)
        
            var config = {
                method: 'delete',
                url: process.env.VUE_APP_BACKEND_API_BASE_URL + '/account/users/' + this.selected_id + '/',
                headers: { 
                'Authorization': 'JWT '+localStorage.jwt,
                'Content-Type': 'application/json'
                },
                // data : values
            };

            axios(config)
            .then((response) => {
                // console.log(response.data)
                if (response.data.detail) {
                    console.log('error!')

                    Swal.fire({
                        position: 'bottom',
                        toast: true,
                        icon: 'warning',
                        iconColor: '#fff',
                        title: '<span style="color:#fff">'+ response.data.detail +'</span>',
                        // title: 'Your message has been sent',
                        showConfirmButton: false,
                        timer: 5000,
                        // timerProgressBar: true,
                        // backdrop: false
                        background: '#dd0000'
                    })
                } else {
                    Swal.fire({
                    position: 'bottom',
                    toast: true,
                    icon: 'success',
                    iconColor: '#fff',
                    title: '<span style="color:#fff">User has been deleted!</span>',
                    // title: 'Your message has been sent',
                    showConfirmButton: false,
                    timer: 5000,
                    // timerProgressBar: true,
                    // backdrop: false
                    background: '#dd0000'
                    })
                    this.refresh()
                }

                this.submitting_data_2 = false
            })
            .catch((error) => {
                console.log('error!')
                console.log(error.response.data)
                
            });    

    },
    user_handleSubmit (e) {
      e.preventDefault();

      if (this.add_or_edit=="Create") {
            this.form_2.validateFields((err, values) => {
                // console.log('>>>>', this.form_2);
                // console.log(err, values)

                if (this.user_password.data.trim()==='') {
                    this.user_password.error=true
                    this.user_password.validateStatus='error'
                    this.user_password.help='Please enter password'
                }

                if (!err && !this.user_password.error) {
                    this.submitting_data_2 = true
                    this.error_2 = false
                    this.error_message_2 = ''

                    this.user_password.error=false
                    this.user_password.validateStatus=''
                    this.user_password.help=''


                    values.password = this.user_password.data.trim()

                    // console.log(values)

                    var config = {
                        method: 'post',
                        url: process.env.VUE_APP_BACKEND_API_BASE_URL + '/account/users/',
                        headers: { 
                        'Authorization': 'JWT '+localStorage.jwt,
                        'Content-Type': 'application/json'
                        },
                        data : values
                    };

                    axios(config)
                    .then((response) => {
                        // console.log(response.data)
                        if (response.data.detail) {
                            console.log('error!')
                            this.handleError_2(response.data.detail)
                        } else {
                            Swal.fire({
                            position: 'bottom',
                            toast: true,
                            icon: 'success',
                            iconColor: '#fff',
                            title: '<span style="color:#fff">New User has been created</span>',
                            // title: 'Your message has been sent',
                            showConfirmButton: false,
                            timer: 5000,
                            // timerProgressBar: true,
                            // backdrop: false
                            background: '#0c9463' 
                            })
                            this.handleCancel_2()
                            this.refresh()
                        }

                        this.submitting_data_2 = false
                    })
                    .catch((error) => {
                        console.log('error!')
                        console.log(error.response.data)

                        this.handleError_2('unknown error!')

                        this.submitting_data_2 = false
                    });    

                }
            });
        
            this.submitting_data_2 = false
      } else if (this.add_or_edit=="Edit") {
            this.form_2.validateFields((err, values) => {
                // console.log('>>>>', this.form_2);
                // console.log(err, values)

                if (!err || err[0]=="password is required") {
                    this.submitting_data_2 = true
                    this.error_2 = false
                    this.error_message_2 = ''

                    // console.log(values)

                    if (this.user_password.data.trim()!='') {
                        values.password = this.user_password.data.trim()
                    }
                
                    // console.log(values)

                    var config = {
                        method: 'patch',
                        url: process.env.VUE_APP_BACKEND_API_BASE_URL + '/account/users/' + this.selected_id + '/',
                        headers: { 
                        'Authorization': 'JWT '+localStorage.jwt,
                        'Content-Type': 'application/json'
                        },
                        data : values
                    };

                    axios(config)
                    .then((response) => {
                        // console.log(response.data)
                        if (response.data.detail) {
                            console.log('error!')
                            this.handleError_2(response.data.detail)
                        } else {
                            Swal.fire({
                            position: 'bottom',
                            toast: true,
                            icon: 'success',
                            iconColor: '#fff',
                            title: '<span style="color:#fff">User information has been updated</span>',
                            // title: 'Your message has been sent',
                            showConfirmButton: false,
                            timer: 5000,
                            // timerProgressBar: true,
                            // backdrop: false
                            background: '#0c9463' 
                            })
                            this.handleCancel_2()
                            this.refresh()
                        }

                        this.submitting_data_2 = false
                    })
                    .catch((error) => {
                        console.log('error!')
                        console.log(error.response.data)

                        this.handleError_2('unknown error!')

                        this.submitting_data_2 = false
                    });    

                }
            });
        
            this.submitting_data_2 = false
      } 

    },

    refresh () {
      this.add_edit_mode = false
      this.fetchData()
    },

  }
};
</script>


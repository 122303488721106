<template>
  <a-layout id="components-layout-demo-responsive" style="min-height: 100vh">
    <a-layout-sider
      breakpoint="md"
      collapsed-width="0"
      @collapse="onCollapse"
      @breakpoint="onBreakpoint"
    >
    
      <div class="sidebar-logo">EasyDigital SMS</div>
      <br/>
      <div class="sidebar-logo">Welcome, <br/>{{ user }}</div>
      <br/>
      <br/>
      <a-menu
        theme="dark"
        mode="inline" 
        :selectable="true" 
        >
          <!-- <a-menu-item key="home">
            <router-link to="/">
              <a-icon type="line-chart" />
              <span class="nav-text">Dashboard</span>
            </router-link>
          </a-menu-item> -->
        
        <a-menu-item key="send">
          <router-link to="/send">
            <a-icon type="message" />
            <span class="nav-text">New Message</span>
          </router-link>
        </a-menu-item>

        <!-- <a-menu-item key="send-excel" :disabled="can_upload"> -->        
        <a-menu-item key="send-excel">
          <router-link to="/send-excel">
            <a-icon type="message" />
            <span class="nav-text">Excel Bulk Send</span>
          </router-link>
        </a-menu-item>

        <a-menu-item key="logs">
          <router-link to="/">
            <a-icon type="appstore" />
            <span class="nav-text">Sent Messages</span>
          </router-link>
        </a-menu-item>

        <a-menu-item key="balance">
          <router-link to="/balance">
            <a-icon type="credit-card" />
            <span class="nav-text">Balance</span>
          </router-link>
        </a-menu-item>

        <a-menu-item key="settings">
          <router-link to="/settings">
            <a-icon type="setting" theme="outlined" />
            <span class="nav-text">Settings</span>
          </router-link>
        </a-menu-item>

        <a-menu-item key="logout" @click="logout">
          <!-- <router-link to="/logout"> -->
            <a-icon type="blank" />
            <span class="nav-text">Logout</span>
          <!-- </router-link> -->
        </a-menu-item>

      </a-menu>
    </a-layout-sider>

    <a-layout>
      <!-- <a-layout-header :style="{ background: '#fff', padding: 0 }">
        Welcome, {{user}}
      </a-layout-header> -->

      <a-layout-content :style="{ margin: '24px 16px 16px' }">
        <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
          <router-view/>
        </div>
      </a-layout-content>
      <!-- <a-layout-footer style="textAlign: center">
        An EasyDigital Service
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>


<script>
export default {
  data () {
    return {
      user: localStorage.user_fullname,
      can_upload: !localStorage.upload
    };
  },
  methods: {
    onCollapse(collapsed, type) {
      console.log('onCollapse:', collapsed, type);
    },
    onBreakpoint(broken) {
      console.log('onBreakpoint:', broken);
    },
    logout () {
        localStorage.removeItem('jwt')
        localStorage.removeItem('user_fullname')
        localStorage.removeItem('account')

        this.$router.push('/login')
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  }
};
</script>

<style>
#components-layout-demo-responsive .sidebar-logo {
  height: 32px;
  color: whitesmoke;
  /* background: rgba(255, 255, 255, 0.2); */
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  /* text-transform: uppercase; */
}

</style>

<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Sent Messages"
      sub-title=""
      @back="() => $router.go(-1)"
    >

      <template slot="extra">
        <a-button-group size="default">
          <a-button key="1" type="primary" icon="reload" @click="refresh()">Refresh</a-button>
        </a-button-group>
      </template>

      <template slot="footer">
      </template>

      <div class="content">
            
            <!-- {{filter.query}}
            <br/> -->
            <br/>

            <div>
                
                <div>
                    <a-space>
                        <a-radio-group default-value="today" button-style="solid" @change="filterRangeChange">
                          <a-radio-button value="today">
                            Today
                          </a-radio-button>
                          <a-radio-button value="yesterday">
                            Yesterday
                          </a-radio-button>
                          <a-radio-button value="last-7-days">
                            Last 7 days
                          </a-radio-button>
                          <a-radio-button value="last-30-days">
                            Last 30 days
                          </a-radio-button>
                          <!-- <a-radio-button value="last-100-days">
                            Last 100 days
                          </a-radio-button> -->
                          <a-radio-button value="custom-range">
                            Custom Range
                          </a-radio-button>
                        </a-radio-group>
                        <a-range-picker :allowClear="false" @change="onDateRangeChange"  :hidden="filter.custom_date_range_hidden" />

                        <a-dropdown>
                          <a-menu slot="overlay" @click="handleDownloadClick">
                            <a-menu-item key="key_download_stats">
                              Statistics Summary (csv)
                            </a-menu-item>
                            <a-menu-item key="key_download_stats_details">
                              Statistics Summary by Sender ID (csv)
                            </a-menu-item>
                            <a-menu-item key="key_download_stats_alert">
                              Statistics Summary by Alert ID (csv)
                            </a-menu-item>
                            <a-menu-item key="key_download_logs">
                              Logs (zipped csv)
                            </a-menu-item>
                          </a-menu>
                          <a-button type="primary"> Download Report<a-icon type="down" /> </a-button>
                        </a-dropdown>
                    </a-space>
                </div>

            </div>

            <br/>

            <a-tabs default-active-key="1" :animated="false">
                <a-tab-pane key="1" tab="Logs">

                    <div>
                        <a-space>
                            <span></span>

                            Telco
                            <a-select :value="filter.operator" default-value="" style="width: 90px" @change="filterOperatorChange">
                              <a-select-option value="">
                                All
                              </a-select-option>
                              <a-select-option value="nt">
                                NT
                              </a-select-option>
                              <a-select-option value="ncell">
                                Ncell
                              </a-select-option>
                              <a-select-option value="smart">
                                Smart
                              </a-select-option>
                            </a-select>

                            <span></span>

                            Status
                            <a-select :value="filter.status" default-value="" style="width: 120px" @change="filterStatusChange">
                              <a-select-option value="">
                                All
                              </a-select-option>
                              <a-select-option value="0">
                                Pending
                              </a-select-option>
                              <a-select-option value="1">
                                Sent
                              </a-select-option>
                              <a-select-option value="2">
                                Failed
                              </a-select-option>
                              <a-select-option value="3">
                                Invalid
                              </a-select-option>         
                              <a-select-option value="4">
                                Cancelled
                              </a-select-option>                      
                            </a-select>

                            <span></span>

                            <a-input-search v-model="filter.search_text" placeholder="Filter by From, To, Tag" style="width: 300px" allow-clear enterButton @search="filterSearch" />

                            <a-button type="secondary" @click="resetFilters">Reset filters</a-button>
                        </a-space>
                    </div>

                    <div style="text-align:right;padding-bottom:10px">
                      <!-- <a-pagination simple :defaultCurrent="1" :current="filter.page" :total="filter.total_page_items" :defaultPageSize="filter.page_size" @change="onPageChange" :show-total="total => `Total ${total} sms`" /> -->
                        <!-- <a-pagination size="small" :total="filter.total_page_items" :show-total="(total, range) => `${range[0]}-${range[1]} of ${total} items`" /> -->
                        <a-pagination
                          size="small"
                          :total="filter.total_page_items"
                          :show-total="(total, range) => `${range[0]}-${range[1]} of ${total} sms`"
                          :page-size="10"
                          :default-current="1"
                          :current="filter.page"
                          :defaultPageSize="filter.page_size"
                          @change="onPageChange"
                          show-quick-jumper
                        />
                    </div>

                    <a-table 
                      :columns="columns"
                      :data-source="data"
                      :scroll="{ x:true }"
                      rowKey="id"
                      size="small"
                      :loading="reloading_data"
                      :pagination="false"
                      >
                          <span slot="id" slot-scope="id,data">
                            <a-tooltip placement="right">
                              <template slot="title">
                                Resend SMS
                              </template>
                              <a-popconfirm
                                :disabled="data.retried==true"
                                title="Resend this sms?"
                                @confirm="() => resendSms(id, data)"
                              >
                                  <a-button key="{{id}}" type="danger" icon="retweet" size="small" :disabled="(data.retried==true)" />
                              </a-popconfirm>
                            </a-tooltip>
                          </span>

                          <span slot="status" slot-scope="status">
                          <a-tag
                            :key="status"
                            :color="status === 0 ? 'geekblue' : status === 1 ? 'green' : status === 4 ? 'yellow': 'red'"
                          >
                            {{ status_key[status] }}
                          </a-tag>
                        </span>
                    </a-table>
                    
                </a-tab-pane>

                <a-tab-pane key="2" tab="Statistics">
                    <div>
                      <a-spin :spinning="reloading_data">
                        <a-skeleton loading>
                        </a-skeleton>
                      </a-spin>
                    </div>
                </a-tab-pane>
            </a-tabs>

      </div>
    </a-page-header>
  </div>
</template>


<script>
import moment from 'moment';
const Swal = require('sweetalert2');
const axios = require('axios');

const columns = [
  {
    title: 'Telco',
    key: 'telco',
    dataIndex: 'telco',
    // fixed: 'left',
    // width: '100px',
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
    // fixed: 'left',
    // width: '150px',
  },
  {
    title: 'To',
    dataIndex: 'to',
    key: 'to',
    // fixed: 'left',
    // width: '120px',
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
    // ellipsis: true,
  },
  {
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag',
    // ellipsis: true,
    fixed: 'right',
  },
  {
    title: 'Pages',
    dataIndex: 'page_count',
    key: 'page_count',
    fixed: 'right',
    // width: '75px',
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
    fixed: 'right',
    // width: '75px',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fixed: 'right',
    // width: '125px',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    fixed: 'right',
    // width: '150px',
  },
  {
    title: 'Actions',
    key: 'id',
    dataIndex: 'id',
    fixed: 'right',
    // width: '100px',
    scopedSlots: { customRender: 'id' }
  },
];

function onPageChange(page, pageSize) {
  // console.log('params', page , pageSize);
  // console.log(page);
  // console.log(pageSize);

  this.filter.page_size = pageSize
  this.filter.page = page

  this.queryBuilder()
  this.refresh()
}

function queryBuilder() {
  this.filter.query = `?page=${this.filter.page}&page_size=${this.filter.page_size}&operator=${this.filter.operator}&status=${this.filter.status}&search=${this.filter.search_text}&range_from=${this.filter.range_from}&range_to=${this.filter.range_to}`
  // console.log(this.filter.query)
}

export default {
  data() {
    return {
      data: [],
      columns,
      reloading_data: true,
      total_pages: 5,
      total_sms: 7,
      status_key: {
        0: 'Pending',
        1: 'Sent',
        2: 'Failed',
        3: 'Invalid',
        4: 'Cancelled'
      },
      filter: {
        query: '',
        operator: '',
        status: '',
        range_from: moment().format('YYYY-MM-DD'),
        range_to: moment().format('YYYY-MM-DD'),
        custom_range_from: '',
        custom_range_to: '',
        search_text: '',
        page: 1,
        page_size: 10,
        total_page_items: 0,
        custom_date_range_hidden: true,
      },
      
    };
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.queryBuilder()
    this.fetchData()
  },
  methods: {
    resendSms(sms_id, clicked_row_obj) {

      var config = {
        method: 'post',
        url: process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/retry/',
        headers: { 
          'Authorization': 'JWT '+localStorage.jwt,
          'Content-Type': 'application/json'
        },
        data : {"retry_sms_id":sms_id}
      };

      axios(config)
        .then((response) => {
              // console.log(response.data)
              if (response.data.detail) {
                console.log('error!', response.data.detail)

                Swal.fire({
                  position: 'bottom',
                  toast: true,
                  icon: 'error',
                  iconColor: '#fff',
                  title: '<span style="color:#fff">'+response.data.detail+'</span>',
                  // title: 'Your message has been sent',
                  showConfirmButton: false,
                  timer: 10000,
                  // timerProgressBar: true,
                  // backdrop: false
                  background: '#ff0000' 
                })

              } else {  
                const sel_row = this.data.filter(row => row.id==clicked_row_obj.id)[0];
                sel_row.retried = true

                // e.target.disabled = true

                Swal.fire({
                  position: 'bottom',
                  toast: true,
                  icon: 'success',
                  iconColor: '#fff',
                  title: '<span style="color:#fff">SMS has been resent</span>',
                  // title: 'Your message has been sent',
                  showConfirmButton: false,
                  timer: 10000,
                  // timerProgressBar: true,
                  // backdrop: false
                  background: '#0c9463' 
                })
              }

              // this.submitting_data = false
          })
          .catch((error) => {
              console.log('error!')
              console.log(error.response.data)

              Swal.fire({
                  position: 'bottom',
                  toast: true,
                  icon: 'error',
                  iconColor: '#fff',
                  title: '<span style="color:#fff">Error occurred</span>',
                  showConfirmButton: false,
                  timer: 10000,
                  // timerProgressBar: true,
                  // backdrop: false
                  background: '#ff0000' 
                })

              // this.handleError('unknown error!')

              // this.submitting_data = false
          });
    },
    handleDownloadClick(e) {
      if (e.key=='key_download_logs') {
        // console.log('logs', this.filter.range_from, this.filter.range_to)
        var _date_range_from = moment(this.filter.range_from,"YYYY-MM-DD")
        var _date_range_to = moment(this.filter.range_to,"YYYY-MM-DD")
        // console.log(_date_range_from, _date_range_to, typeof(_date_range_from))
        var days_diff=_date_range_to.diff(_date_range_from, 'days')

        // console.log(localStorage.account)

        var days_diff_allowed = 1
        if (localStorage.account=='EasyDigital' || localStorage.account=='MBL') {
          days_diff_allowed = 32
        }

        if (days_diff<=days_diff_allowed) {
            axios({
              method: 'get',
              url: process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/download/logs/?range_from='+this.filter.range_from+'&range_to='+this.filter.range_to,
              responseType: 'blob',
              headers: {
                  Authorization: "JWT " + localStorage.jwt
              }
            })
            .then((response) => {
                    // console.log(response)
                    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                    
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download','sms_log.zip'); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
            })
            .catch((error) => {
                console.log(error)
                // this.reloading_data = false
            });
        } else {
            Swal.fire({
              position: 'bottom',
              toast: true,
              icon: 'warning',
              iconColor: '#fff',
              title: '<span style="color:#fff">\'Logs (zipped csv)\' download date range is currently restricted to '+days_diff_allowed+' day(s)</span>',
              // title: 'Your message has been sent',
              showConfirmButton: false,
              timer: 10000,
              // timerProgressBar: true,
              // backdrop: false
              background: '#ff0000' 
            })
        }
      } else if (e.key=='key_download_stats') {
        console.log('stats', this.filter.range_from, this.filter.range_to)

        axios.get(process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/download/stats/?range_from='+this.filter.range_from+'&range_to='+this.filter.range_to,
        {
          headers: {
              Authorization: "JWT " + localStorage.jwt
          }
        })
        .then((response) => {
            console.log(response.data.data)

            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download','sms_stat_summary.csv'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            
            // this.reloading_data = false
        })
        .catch((error) => {
            console.log(error.response.data)
            // this.reloading_data = false
        }); 
      } else if (e.key=='key_download_stats_details') { 
        console.log('stats detail', this.filter.range_from, this.filter.range_to)

        axios.get(process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/download/stats/detail/?range_from='+this.filter.range_from+'&range_to='+this.filter.range_to,
        {
          headers: {
              Authorization: "JWT " + localStorage.jwt
          }
        })
        .then((response) => {
            console.log(response.data.data)

            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download','sms_stat_summary_by_senderid.csv'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            
            // this.reloading_data = false
        })
        .catch((error) => {
            console.log(error.response.data)
            // this.reloading_data = false
        }); 
      } else if (e.key=='key_download_stats_alert') { 
        console.log('stats detail', this.filter.range_from, this.filter.range_to)

        axios.get(process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/download/stats/alert/?range_from='+this.filter.range_from+'&range_to='+this.filter.range_to,
        {
          headers: {
              Authorization: "JWT " + localStorage.jwt
          }
        })
        .then((response) => {
            console.log(response.data.data)

            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download','sms_stat_summary_by_alertid.csv'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            
            // this.reloading_data = false
        })
        .catch((error) => {
            console.log(error.response.data)
            // this.reloading_data = false
        }); 
      }
    },
    onDateRangeChange(date, dateString) {
      // console.log(date[1]-date[0], dateString)
      // console.log(dateString[0], dateString[1])

      // var diffInMilliseconds = (date[1]-date[0])
      // var diffInDays = (diffInMilliseconds / (60*60*24*1000))

      // if (diffInDays <= 50) {
        this.filter.custom_range_from = dateString[0]
        this.filter.custom_range_to = dateString[1]

        this.filter.range_to = dateString[1]
        this.filter.range_from = dateString[0]
        this.filter.page = 1
        this.queryBuilder()
        this.refresh()
      // }
    },
    // onChangeDate(e) {
    //   console.log(`checked = ${e.target.value}`);
    // },
    onPageChange,
    queryBuilder,
    filterOperatorChange(value) {
      this.filter.operator = value
      this.filter.page=1
      // console.log(`selected ${value}`)

      this.queryBuilder()
      this.refresh()
    },
    filterStatusChange(value) {
      this.filter.status = value
      this.filter.page=1
      // console.log(`selected ${value}`)

      this.queryBuilder()
      this.refresh()
    },
    filterRangeChange(e) {
      var range = e.target.value
      var refresh = true

      var d = moment()

      switch(range) {
        case 'today':
          this.filter.custom_date_range_hidden = true
          this.filter.range_to = d.format('YYYY-MM-DD')
          this.filter.range_from = d.format('YYYY-MM-DD')
          break;
        case 'yesterday':
          this.filter.custom_date_range_hidden = true
          d = d.subtract(1, "days")
          this.filter.range_to = d.format('YYYY-MM-DD')
          this.filter.range_from = d.format('YYYY-MM-DD')
          break;
        case 'last-7-days':
          this.filter.custom_date_range_hidden = true
          this.filter.range_to = d.format('YYYY-MM-DD')
          d = d.subtract(7, "days")
          this.filter.range_from = d.format('YYYY-MM-DD')
          break;
        case 'last-30-days':
          this.filter.custom_date_range_hidden = true
          this.filter.range_to = d.format('YYYY-MM-DD')
          d = d.subtract(30, "days")
          this.filter.range_from = d.format('YYYY-MM-DD')         
          break;
        case 'last-100-days':
          this.filter.custom_date_range_hidden = true
          this.filter.range_to = d.format('YYYY-MM-DD')
          d = d.subtract(100, "days")
          this.filter.range_from = d.format('YYYY-MM-DD')   
          break;
        case 'custom-range':
          this.filter.custom_date_range_hidden = false
          if (this.filter.custom_range_from!='' && this.filter.custom_range_to!='') {
            this.filter.range_to = this.filter.custom_range_to
            this.filter.range_from = this.filter.custom_range_from
          }
          // refresh = false
          break;
        default:  //today
          this.filter.range_to = d.format('YYYY-MM-DD')
          this.filter.range_from = d.format('YYYY-MM-DD')
      }

      if (refresh) {
        this.filter.page = 1
        this.queryBuilder()
        this.refresh()
      }

    },
    filterSearch() {
      this.filter.page = 1

      this.queryBuilder()
      this.refresh()
    },
    resetFilters() {
      this.filter.operator=''
      this.filter.status=''
      this.filter.search_text=''
      this.filter.page=1

      this.queryBuilder()
      this.refresh()
    },
    fetchData () {
      this.reloading_data = true

      axios.get(process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/logs/'+this.filter.query,
              {
                headers: {
                    Authorization: "JWT " + localStorage.jwt
                }
              })
              .then((response) => {
                  // console.log(response.data.data)

                  this.data = response.data.data.log
                  this.filter.page = response.data.data.current_page
                  this.filter.total_page_items = response.data.data.total_count
                  
                  this.reloading_data = false
              })
              .catch((error) => {
                  console.log(error.response.data)
                  this.reloading_data = false
              });      
    },
    refresh () {
      this.fetchData()
    },

},
};
</script>

<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Balance"
      sub-title=""
      @back="() => $router.go(-1)"
    >

      <template slot="extra">
        <a-button-group size="default">
          <a-button key="2" type="" icon="plus-circle" disabled>Topup Balance</a-button>
          <a-button key="1" type="primary" icon="reload" :loading="reloading_data" @click="fetchData()">Refresh</a-button>
        </a-button-group>
      </template>

      <template slot="footer">
      </template>

      <div class="content">
        <div class="main">
        </div>
        
        <div>
            <a-tabs default-active-key="1" :animated="false">
                <a-tab-pane key="1" tab="Overview">
                    <div class="extra">
                      <div
                        :style="{
                          display: 'flex',
                          width: 'max-content',
                          justifyContent: 'flex-end',
                        }"
                      >
                      <a-spin :spinning="reloading_data">
                        <a-statistic
                          title="Currrent Balance"
                          prefix="रु."
                          :value="current_balance"
                          :style="{
                            marginRight: '32px',
                          }"
                        />
                      </a-spin>
                      </div>
                    </div>
                  
                </a-tab-pane>
                <a-tab-pane key="2" tab="Topup History">
                    <div class="content">
                        <a-table 
                          :columns="recharge_history_columns"
                          :pagination="false"
                          :data-source="recharge_history"
                          rowKey="id"
                          size="middle"
                          :loading="reloading_data"
                          >
                        </a-table>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="Usage">
                    <div class="content">
                      <a-space>
                          Year
                          <a-select default-value="2020" style="width: 85px">
                            <a-select-option value="2020">
                              2020
                            </a-select-option>
                          </a-select>
                          <span></span>
                          Aggregation
                          <a-radio-group button-style="solid" :value="usage_aggregation_type" @change="change_usage_aggregation">
                              <a-radio-button value="daily">
                                Daily
                              </a-radio-button>
                              <a-radio-button value="monthly" disabled>
                                Monthly
                              </a-radio-button>
                          </a-radio-group>
                      </a-space>

                      <div>
                        <a-spin :spinning="reloading_data">
                          <a-skeleton loading>
                          </a-skeleton>
                        </a-spin>
                      </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
      </div>

    </a-page-header>

  </div>
</template>


<script>
const axios = require('axios');

const recharge_history_columns = [
  {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
  },
  {
    title: 'Initial Balance',
    key: 'initial_balance',
    dataIndex: 'initial_balance',
  },
  {
    title: 'Amount',
    key: 'amount',
    dataIndex: 'amount',
  },
  {
    title: 'Amount action',
    key: 'action',
    dataIndex: 'action',
  },
  {
    title: 'New balance',
    key: 'new_balance',
    dataIndex: 'new_balance',
  },

]

export default {
  data() {
    return {
      reloading_data: true,
      current_balance: 0.00,
      recharge_history_columns,
      recharge_history: null,
      usage_aggregation_type: 'daily'
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  // watch: {
  //   // call again the method if the route changes
  //   '$route': 'fetchData'
  // },
  methods: {
    fetchData() {
      this.reloading_data = true

      axios.get(process.env.VUE_APP_BACKEND_API_BASE_URL + '/sms/balance/',
              {
                headers: {
                    Authorization: "JWT " + localStorage.jwt
                }
              })
              .then((response) => {
                  // console.log(response.data)
                  this.current_balance = response.data['current_balance']
                  this.recharge_history = response.data['recharge_history']
                  this.reloading_data = false
              })
              .catch((error) => {
                  console.log(error.response.data)
                  this.reloading_data = false
              });
    },
    change_usage_aggregation(e) {
      this.usage_aggregation_type = e.target.value;
    }
  }
}
</script>


<style>
tr:last-child td {
  padding-bottom: 0;
}
#components-page-header-demo-responsive .content {
  display: flex;
}
#components-page-header-demo-responsive .ant-statistic-content {
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 576px) {
  #components-page-header-demo-responsive .content {
    display: block;
  }

  #components-page-header-demo-responsive .main {
    width: 100%;
    margin-bottom: 12px;
  }

  #components-page-header-demo-responsive .extra {
    width: 100%;
    margin-left: 0;
    text-align: left;
  }
}
</style>

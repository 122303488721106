<template>
<a-layout>
<a-layout-content
        :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '100px', width: '500px' }"
      >
  <a-form
    id="components-form-demo-normal-login"
    :form="form"
    class="login-form"
    @submit="handleSubmit"
  >
    <a-form-item>
      <a-input
        v-decorator="[
          'userName',
          { rules: [{ required: true, message: 'Please input your username!' }] },
        ]"
        placeholder="Username"
      >
        <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input
        v-decorator="[
          'password',
          { rules: [{ required: true, message: 'Please input your Password!' }] },
        ]"
        type="password"
        placeholder="Password"
      >
        <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-alert v-if='error' :message='error_message' type="error" show-icon />

      <a-button type="primary" html-type="submit" class="login-form-button">
        Log in
      </a-button>
    </a-form-item>
  </a-form>

</a-layout-content>
</a-layout>
</template>

<script>
const axios = require('axios');


export default {
  data() {
    return {
        error: false,
        error_message: 'Error',
    }
  },
  beforeCreate() {
      if (localStorage.jwt != null) {
          this.$router.push('/');
      }
    
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.error = false
      this.form.validateFields((err, values) => {        
        if (!err) {
          axios.post(process.env.VUE_APP_BACKEND_API_BASE_URL + '/auth/api-token-auth/', {
              username: values.userName,
              password: values.password
              })
              .then((response) => {
                  localStorage.setItem('jwt', response.data.token)
                  localStorage.setItem('user_fullname', response.data.user)
                  localStorage.setItem('account', response.data.account)
                  localStorage.setItem('upload', response.data.upload)

                  if (localStorage.jwt != null ) {                      
                      if(this.$route.query.nextUrl != null) {
                          this.$router.push(this.$route.query.nextUrl)
                      } else {
                          this.$router.push('/')
                      }
                  }
              })
              .catch((error) => {
                  this.error_message = error.response.data.non_field_errors[0]||'Unable to log in with provided credentials.'
                  this.error = true
              });

          }
      });
    },
  },
};
</script>
<style>
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
</style>